import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useLocation } from "react-router-dom";
import Modal from 'react-modal';
import {Stack} from '@fluentui/react';
import bright from '../svg/bright.svg';
import ChartLabel from '../Components/ChartLabel';
import Carousel from '@brainhubeu/react-carousel';
import portfolioMobile from '../svg/portfolioMobile.svg';
import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";

interface Props {
    location: any
}

const Jobs:  React.FC<Props> = props => {
    console.log(props.location.state.jobsList);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [anualProjected, setAnualProjected] = useState('');
    const [perCentChange, setPerCentChange] = useState('');
    const [wageMin, setWageMin] = useState(0);
    const [wageMid, setWageMid] = useState(0);
    const [wageMax, setWageMax] = useState(0);
    const [isBright, setIsBright] = useState(false);
    const [educationTitle, setEducationTitle] = useState('');
    const [experienceTitle, setExperienceTitle] = useState('');
    const [trainingTitle, setTrainingTitle] = useState('');
    const [trainingPrograms, setTrainingPrograms] = useState(Array());
    const [cOSVideoURL, setCOSVideoURL] = useState('');
    const data = [
        {
          name:  'Low', Wages: wageMin
        },
        {
          name: 'Median', Wages: wageMid
        },
        {
          name: 'High', Wages: wageMax
        }      
    ];

    async function openModal(onetCode) {
        fetch('https://career-assessment-api-uat.equalfuture.com/occupations/'+ onetCode+'/US/?training=true&videos=true&wages=true&projectedEmployment=true&skills=true&knowledge=true&trainingPrograms=true',
      {
        method: 'GET'
      }
    )
      .then(res => res.json())
      .then((data) => {
        console.log(data);
        setModalIsOpen(true);
        document.getElementById('container').style.filter = 'blur(5px)'
        setModalTitle(data.OccupationDetail[0].OnetTitle);
        setPerCentChange(data.OccupationDetail[0].Projections.Projections[0].PerCentChange);
        setAnualProjected(data.OccupationDetail[0].Projections.Projections[0].ProjectedAnnualJobOpening);
        setEducationTitle(data.OccupationDetail[0].EducationTraining.EducationTitle);
        setExperienceTitle(data.OccupationDetail[0].EducationTraining.ExperienceTitle);
        setTrainingTitle(data.OccupationDetail[0].EducationTraining.TrainingTitle);
        setTrainingPrograms(data.OccupationDetail[0].TrainingPrograms);
        setCOSVideoURL(data.OccupationDetail[0].COSVideoURL);
        if (data.OccupationDetail[0].Wages.NationalWagesList[1].RateType == "Annual"){
            setWageMin(data.OccupationDetail[0].Wages.NationalWagesList[1].Pct10);
            setWageMid(data.OccupationDetail[0].Wages.NationalWagesList[1].Median);
            setWageMax(data.OccupationDetail[0].Wages.NationalWagesList[1].Pct90);
        }else{
            setWageMin(data.OccupationDetail[0].Wages.NationalWagesList[0].Pct10);
            setWageMid(data.OccupationDetail[0].Wages.NationalWagesList[0].Median);
            setWageMax(data.OccupationDetail[0].Wages.NationalWagesList[0].Pct90);
        }
        
        if(data.OccupationDetail[0].BrightOutlook == "Bright"){
            setIsBright(true)
        }
      })
      .catch(console.log)
        
    }
    function closeModal() {
        setModalIsOpen(false);
        document.getElementById('container').style.filter = 'blur(0px)'
    }
    const myparam = props.location.state.jobsList;

    return(
        <Stack horizontalAlign='center'>
            <BrowserView>
                <div style={container} id='container'>
                    <div>
                        <p style={title}>JOBS BASED ON MY INTERESTS</p>
                        <label style={greyText}>Careers</label>
                        <hr style={divider}/>

                    </div>
                    {myparam.map((row, i) => (
                        <ul
                        style={{
                            listStyle: "none",
                            display: 'inline',
                            
                            color: "#5A5A5A",
                        }}
                        key={i}
                        >   
                            <li onClick={ async ()=> {await openModal(row.OnetCode)}} style={{marginTop:20, marginBottom:10}}><label style={subtitleLabel}>{row.Name}</label></li>
                            <hr style={divider}/>
                            <Stack horizontalAlign='center'>
                                    <Modal
                                        isOpen={modalIsOpen}
                                        onRequestClose={closeModal}
                                        style={browserStyle}
                                    >
                                        <p style={modalTitleStyle}>{modalTitle}</p>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignContent:'center', marginLeft: 'auto', marginRight: 'auto', width: '100%', marginBottom: 20, marginTop: 20 }}>
                                            <p style={modalMobileTitleStyle}>EDUCATION AND EXPERIENCE TO GET STARTED:</p>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignContent:'center', marginLeft: 'auto', marginRight: 'auto', width: '100%', marginBottom: 20, }}>
                                            <p style={modalContentTitleMobile}>People starting in this career usually have:</p>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignContent:'center', marginLeft: 'auto', marginRight: 'auto', width: '100%', marginBottom: 20, }}>
                                            <ul style={ulContentMobile}>
                                                <li><label>{educationTitle}</label></li>
                                                <li><label>{experienceTitle}</label></li>
                                                <li><label>{trainingTitle}</label></li>
                                            </ul>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignContent:'center', marginLeft: 'auto', marginRight: 'auto', width: '100%', marginBottom: 20, marginTop: 20 }}>
                                            <p style={programsPrepare}>Programs that can prepare you:</p>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignContent:'center', marginLeft: 'auto', marginRight: 'auto', width: '100%', marginBottom: 20, }}>
                                            <ul style={ulContentMobile}>
                                                {trainingPrograms != null && trainingPrograms.length ? trainingPrograms.map((row, i) => (
                                                    <li><label style={yellowText}>{row}</label></li>
                                                )): <li><label style={yellowText}>No data</label></li>}
                                            </ul>
                                        </div>
                                        <p style={modalSubTitleStyle}>PROJECTED EMPLOYMENT</p>

                                        <div style={{float:'left', marginLeft:120}}>
                                                    <label style={modalPercentMobile}>{perCentChange}%</label>
                                                    <p style={normalTextWebLeft}>Percent change</p>
                                                </div>
                                                <div style={{float:'right', marginRight:140}}>
                                                    <label style={modalGreenMobile}>{anualProjected}</label>
                                                    <p style={normalTextWeb}>Anual projected job openings</p>
                                                </div>
                                        <div style={{marginTop:170, width:'100%', display:'block'}}>
                                            <p style={modalSubTitleStyle}>OUTLOOK: WILL THERE BE JOBS?</p>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignContent:'center', marginLeft: 'auto', marginRight: 'auto', width: '100%', marginBottom: 20, marginTop: 20 }}>
                                            <div style={{float:'left' as 'left', width: 90}}>
                                                <img src={bright} style={{marginLeft:-20}}/>
                                            </div>
                                            <div style={{float: 'right' as 'right', marginBottom:30, marginTop:20}}>
                                                <label style={modalGrey}>New job opportunities are very likely in the future.</label>
                                                <p style={modalGrey}>This occupation is expected to grow much faster than average</p>
                                            </div>
                                        </div>
                                        <div style={{marginTop:90, display: 'block', margin:'0'}}>
                                        <p style={modalSubTitleStyle}>TIPICAL WAGES</p>
                                        <p style={modalGrey}>Anual Wages for {modalTitle} in United States</p>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignContent:'center', marginLeft: 'auto', marginRight: 'auto', width: '100%', marginBottom: 20, marginTop: 20 }}>
                                            <BarChart height={173} width={400} data={data} margin={{
                                                top: 60, bottom: 60, left:60
                                            }}>
                                            <XAxis axisLine={false} tickLine={false} dataKey="name"/>
                                            <Bar maxBarSize={300} dataKey="Wages" fill="#32C5FF" label={ChartLabel} radius={[10, 10, 10, 10]}/>
                                            </BarChart>
                                        </div>                      
                                        </div>

                                    </Modal>
                                
                            </Stack>
                        </ul>
                    ))}
                </div>
            </BrowserView>

            <MobileView>
                <div style={{width:'100%', display:'block', margin:'auto', background: 'rgba(255, 255, 255, 0.9)'}} id='container'>
                    <div>
                        <p style={modalMobileTitleStyle}>JOBS BASED ON MY INTERESTS</p>
                        <label style={greyTextMobile}>Careers</label>
                        <hr style={divider}/>
                    </div>
                    {myparam.map((row, i) => (
                        <ul
                            style={{
                                listStyle: "none",
                                display: 'inline',
                                color: "#5A5A5A",
                            }}
                            key={i}
                        >   
                        <li onClick={ async ()=> {await openModal(row.OnetCode)}} style={{marginTop:20, marginBottom:10}}><label style={careersText}>{row.Name}</label></li>
                        <hr style={divider}/>
                        <div style={{width:'100%', opacity:0.5}}>
                            <Modal
                                isOpen={modalIsOpen}
                                onRequestClose={closeModal}
                                style={mobileStyle}
                            >
                                <Carousel slidesPerPage={2} offset={5} centered itemWidth={290} clickToChange>
                                    <div style={divMobile}>
                                        <div style={titleDiv}>
                                            <p style={modalTitleStyle}>{modalTitle}</p>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignContent:'center', marginLeft: 'auto', marginRight: 'auto', width: '100%', marginBottom: 20, marginTop: 20 }}>
                                            <p style={modalMobileTitleStyle}>EDUCATION AND EXPERIENCE TO GET STARTED:</p>
                                            <p style={modalContentTitleMobile}>People starting in this career usually have:</p>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignContent:'center', marginLeft: 'auto', marginRight: 'auto', width: '100%', marginBottom: 20, marginTop: 20 }}>
                                                <ul style={ulContentMobile}>
                                                    <li><label>{educationTitle}</label></li>
                                                    <li><label>{experienceTitle}</label></li>
                                                    <li><label>{trainingTitle}</label></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div style={{marginBottom:20}}>
                                            <p style={modalContentTitleMobile}>Programs that can prepare you:</p>
                                            <ul style={ulContentMobile}>
                                                {trainingPrograms != null && trainingPrograms.length ? trainingPrograms.slice(0, 5).map((row, i) => (
                                                    <li><label style={yellowText}>{row}</label></li>
                                                )): <li><label style={yellowText}>No data</label></li>}
                                            </ul>
                                        </div>
                                
                                    </div>

                                    <div style={divMobile}>
                                        <div style={titleDiv}>
                                            <p style={modalTitleStyle}>{modalTitle}</p>
                                        </div>
                                        <div style={{marginTop:80, marginRight:5, marginLeft:5}}>
                                            <p style={modalContentTitleMobile}>PROJECTED EMPLOYMENT</p>
                                        </div>
                                        <div style={{float:'left', marginLeft:20, marginTop:-15}}>
                                            <label style={modalPercentMobile}>{perCentChange}%</label>
                                            <p style={normalText}>Percent change</p>
                                        </div>
                                        <div style={{float:'right', marginRight:20, marginTop:-15}}>
                                            <label style={modalGreenMobile}>{anualProjected}</label>
                                            <p style={normalText}>Anual projected job openings</p>
                                        </div>
                                        <div style={{marginTop:90, width:'100%'}}>
                                            <p style={modalContentTitleMobile}>OUTLOOK: WILL THERE BE JOBS?</p>
                                            <img src={bright} style={{display: 'block', margin:'auto', height:'60.29px', width:'73.79px'}}/>
                                        </div>
                                        <div style={{display: 'inline-block', margin:'0 auto'}}>
                                            <div style={{marginBottom:30, marginTop:0, marginLeft:20, marginRight:20}}>
                                                <div style={{marginTop:-20}}>
                                                <p style={modalContentTitleMobile}>New job opportunities are very likely in the future.</p>
                                                </div>
                                                <div style={{marginTop:-20}}>
                                                <p style={modalContentTitleMobile}>This occupation is expected to grow much faster than average</p>
                                                </div>
                                                <div style={{marginTop:-20}}>
                                                <p style={yellowText}>Find job openings</p>
                                                </div>
                                                <div style={{marginTop:-20}}>
                                                <a href={cOSVideoURL} style={modalAMobile}>HANDSHAKE LINK</a>
                                                </div>

                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div style={divMobile}>
                                        <div style={titleDiv}>
                                            <p style={modalTitleStyle}>{modalTitle}</p>
                                        </div>
                                        <div style={{marginTop:80, marginRight:5, marginLeft:5}}>
                                            <p style={modalSubTitleStyle}>TIPICAL WAGES</p>
                                            <p style={modalContentTitleMobile}>Anual Wages for {modalTitle} in United States</p>
                                            <div style={{display:'block', margin:'auto'}}></div>
                                                <ResponsiveContainer height={170} width="80%">
                                                    <BarChart data={data} margin={{
                                                        top: 60, bottom: 60, left:50
                                                    }}>
                                                        <XAxis axisLine={false} tickLine={false} dataKey="name" fontFamily={'brandon-grotesque, sans-serif'} stroke={"#293861"} fontWeight={600}/>

                                                        <Bar maxBarSize={300} dataKey="Wages" fill="#32C5FF" label={ChartLabel} radius={[10, 10, 10, 10]}/>
                                                    </BarChart>
                                                </ResponsiveContainer>
                                        </div>
                                    </div>                
                                </Carousel>
                            </Modal>
                        </div>
                    </ul>
                ))}
            </div>
        </MobileView>
    </Stack>
    )
}

export default Jobs;

const container = {
    borderRadius: 10, 
    height: '100%', 
    width: '100%', 
    backgroundColor: '#fff', 
    padding: 40, 
    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
    overflow: 'auto' as 'auto',
    marginTop:20,
    marginBottom:20
};

const browserStyle = {
    content: {
        justifyContent: "center" as "center",
        alignItems: "middle" as "middle",
        display: 'block',
        margin: 'auto',
        borderRadius: 10,
        height: "90%",
        width: '40%',
        borderStyle: 'none' as 'none',
        backgroundColor: '#fff',
        boxShadow: '2px 2px 10px rgba(0,0,0,0.11)',
    },
}
  const divMobile = {
    borderRadius: 10,
    width:'96%',
    height:450,
    position:'relative' as 'relative',
    margin:0, padding:0,
    borderStyle: 'none' as 'none',
    backgroundColor: '#fff', 
    boxShadow: '2px 2px 10px rgba(0,0,0,0.11)',
    marginBottom:30
  }
  const mobileStyle = {
    content: {
        position:'relative' as 'relative',
        height:"auto" as "auto",
        width:'98%',
        borderStyle: 'none' as 'none',
        backgroundColor: '#fff', 
        marginLeft:-40
    },
  };

  const title = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 'calc(1em + 0.7vw)',
    lineHeight: '35px',
    textAlign: 'center' as 'center',
    letterSpacing: '3.21429px',
    color:'#293861'
  };

  const subtitleLabel = {
    cursor:'pointer',
    fontSize: 'calc(0.8em + 0.1vw)',
    lineHeight: '17px',
    textAlign: 'left' as 'left',
    fontFamily: 'brandon-grotesque, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '2.14286px',
    color: '#293861'
  };

  const greyText = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: 'calc(1em + 0.1vw)',
    lineHeight: '19px',
    fontWeight: 500,
    letterSpacing: '0.964285px',
    textTransform: 'uppercase'as 'uppercase',
    color: '#9EA4B3',
  };
  const greyTextMobile = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: 'calc(0.5em + 0.1vw)',
    lineHeight: '19px',
    fontWeight: 500,
    letterSpacing: '0.964285px',
    textTransform: 'uppercase'as 'uppercase',
    color: '#9EA4B3',
  };
const modalTitleStyle = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: 'calc(1em + 0.7vw)',
    lineHeight: '28px',
    textAlign: 'center' as 'center',
    letterSpacing: '1px',
    fontWeight: 600,
    textTransform: 'uppercase' as 'uppercase',
    color: '#D679F3'
};
const modalMobileTitleStyle = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: 'calc(0.5em + 0.1vw)',
    lineHeight: '28px',
    textAlign: 'center' as 'center',
    fontWeight: 700,
    textTransform: 'uppercase' as 'uppercase',
    color: '#293861'
};
const modalContentTitleMobile = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: 'calc(0.8em + 0.3vw)',
    lineHeight: '28px',
    textAlign: 'center' as 'center',
    fontWeight: 700,
    color: '#293861'
};
const programsPrepare = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: 'calc(0.8em + 0.3vw)',
    lineHeight: '28px',
    textAlign: 'center' as 'center',
    fontWeight: 700,
    color: '#293861',
    marginLeft:-10
};
const modalAMobile = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: 'calc(0.8em + 0.3vw)',
    lineHeight: '28px',
    textAlign: 'center' as 'center',
    fontWeight: 700,
    color: '#293861',
    display:'block'
}
const yellowText = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: 'calc(0.8em + 0.3vw)',
    lineHeight: '28px',
    textAlign: 'center' as 'center',
    fontWeight: 700,
    color: '#fab821'
}
const ulContentMobile = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: 'calc(0.7em + 0.3vw)',
    display:'inline-table',
    textAlign: 'center' as 'center',
    fontWeight: 600,
    color: '#293861'
};
const normalText = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: 'calc(0.7em + 0.3vw)',
    textAlign: 'center' as 'center',
    fontWeight: 600,
    color: '#293861',
    width:90
}
const careersText = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: 'calc(0.7em + 0.3vw)',
    textAlign: 'center' as 'center',
    fontWeight: 400,
    color: '#293861',
    width:90
}
const normalTextWebLeft = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: 'calc(0.7em + 0.3vw)',
    textAlign: 'center' as 'center',
    fontWeight: 600,
    color: '#293861',
    width:90,
    marginTop:-0.1
}
const normalTextWeb = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: 'calc(0.7em + 0.3vw)',
    textAlign: 'center' as 'center',
    fontWeight: 600,
    color: '#293861',
    width:120
}
const modalSubTitleStyle = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: 'calc(1em + 0.5vw)',
    lineHeight: '28px',
    textAlign: 'center' as 'center',
    letterSpacing: '1px',
    fontWeight: 700,
    textTransform: 'uppercase' as 'uppercase',
    color: '#293861'
};
const modalGreenMobile = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: 'calc(1em + 0.5vw)',
    float: 'right' as 'right',
    lineHeight: '28px',
    marginTop: 12,
    width:100,
    textAlign: 'center' as 'center',
    letterSpacing: '1px',
    fontWeight: 700,
    textTransform: 'uppercase' as 'uppercase',
    color: '#86C443',
};
const modalGreen = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: 'calc(1em + 0.5vw)',
    float: 'right' as 'right',
    lineHeight: '28px',
    marginTop:-3,
    textAlign: 'left' as 'left',
    letterSpacing: '1px',
    fontWeight: 700,
    marginRight:50,
    textTransform: 'uppercase' as 'uppercase',
    color: '#86C443',
};
const modalGreyRight = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: 'calc(1em + 0.1vw)',
    lineHeight: '14px',
    marginTop:2,
    marginRight:-100,
    float: 'right' as 'right',
    textAlign: 'center' as 'center',
    color: '#9EA4B3',
};
const modalGreyLeft = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: 'calc(1em + 0.1vw)',
    lineHeight: '14px',
    float: 'left' as 'left',
    textAlign: 'center' as 'center',
    color: '#9EA4B3',
};
const modalPercent = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: 'calc(1em + 0.5vw)',
    lineHeight: '14px',
    textAlign: 'right' as 'right',
    color: '#293861',
};

const modalPercentMobile = {
    marginTop: 15,
    marginLeft: 20,
    display:'inline-block' as 'inline-block',
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: 'calc(1em + 0.5vw)',
    lineHeight: '14px',
    fontWeight:700,
    textAlign: 'center' as 'center',
    color: '#293861',
};
const modalGrey = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: 'calc(0.8em + 0.1vw)',
    lineHeight: '14px',
    textAlign: 'center' as 'center',
    color: '#9EA4B3',
};

const divider = {
    width:'100%',
    borderTop: '0.1px solid #EEEEEE'
}

const titleDiv = {
    borderBottomLeftRadius:0, 
    borderBottomRightRadius:0,
    top:0, 
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10, 
    position:'absolute' as 'absolute', 
    height:'auto' as 'auto', 
    minHeight:'52px', 
    backgroundColor:'#F5D5FF', 
    width:'100%', 
    alignItems:'center' as 'center'
}

